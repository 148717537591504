import React from 'react'
import { Link } from 'react-router-dom'
import './ProjectTile.sass'
import Img from '../../../ui/Img/Img'

function ProjectTile({ link, avatar, title, category }) {
  return (
    <Link to={link}>
      <div className="ProjectTile">
        <div className="ProjectTile-Img">
          <Img src={avatar} alt={title} />
        </div>
        <div className="Project-Info">
          <p className="Title">{title}</p>
          <p className="TechInfo">
            <span className="TechInfo-Category">{category}</span>
          </p>
        </div>
      </div>
    </Link>
  )
}

export default ProjectTile
