import React from 'react'
import Icon from '../Icon/Icon'
import './CheckBlock.sass'

function CheckBlock({ text, icon = 'check-square' }) {
  return (
    <div className="CheckBlock">
      <Icon name={icon} weight="solid" />
      <p>{text}</p>
    </div>
  )
}

export default CheckBlock
