import React from 'react'
import ToTopButton from '../../ui/ToTopButton/ToTopButton'
import './Footer.sass'

function Footer() {
  return (
    <footer className="Footer">
      <p>
        © {new Date().getFullYear()} | Студия One Day
        <br />
        Авторское право на все материалы, размещенные на сайте, пренадлежит
        студии One Day. Копирование материалов на сайте запрещено.
      </p>
      <ToTopButton />
    </footer>
  )
}

export default Footer
