import React, { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Portfolio.sass'
import Slider from '../../ui/Slider/Slider'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Skeleton from '../../ui/Skeleton/Skeleton'
import { HashLink } from 'react-router-hash-link'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import { db } from '../../config/firebase'
import { find, isEmpty, orderBy } from 'lodash'
import { categories } from '../../pages/ProjectPage/ManageProjectPage'
import { withRouter } from 'react-router'
import Media from 'react-media'
import PositionPopUp from './PositionPopUp/PositionPopUp'

function Portfolio({ ...router }) {
  const { user } = useContext(AuthContext)
  const [slides, setSlides] = useState(null)
  const [displayedSlides, setDisplayedSlides] = useState(null)
  const scrollOffset = -64
  const [showPositionPopUp, setShowPositionPopUp] = useState(false)

  const [nav, setNav] = useState([
    {
      title: 'Видео',
      isActive: false,
      id: '#portfolio-video',
      scrollOffset,
      type: 'video',
    },
    {
      title: 'Фото: Елена Нефёдова',
      isActive: false,
      id: '#portfolio-photo-elena',
      scrollOffset,
      type: 'photo',
      author: 'Y00cIUT5l2hf8XvOREqW5jhZNoW2',
    },
    {
      title: 'Фото: Серафима Столярова',
      isActive: false,
      id: '#portfolio-photo-serafima',
      scrollOffset,
      type: 'photo',
      author: 'HeEoslIiF4fdwoLCw1cpjZXPmAc2',
    },
    {
      title: 'Флористика и декор',
      isActive: false,
      id: '#portfolio-floristics-and-decoration',
      scrollOffset,
      type: 'floristics',
    },
  ])

  useEffect(() => {
    db.collection('projects').onSnapshot((snap) => {
      const data = []
      snap.forEach((d) => {
        const project = d.data()

        data.push({
          id: d.id,
          link: `/project/${d.id}`,
          avatar: !isEmpty(project.photos)
            ? find(project.photos, ['isAvatar', true]).publicUrl
            : null,
          title: project.title,
          ...(project.category
            ? {
                category: find(categories, ['value', project.category])
                  ? find(categories, ['value', project.category]).label
                  : '',
              }
            : {}),
          ...project,
        })
      })
      setSlides(
        orderBy(
          user ? data : data.filter((d) => d.isPublished && true),
          ['pos'],
          ['asc']
        )
      )
    })
  }, [])

  useEffect(() => {
    if (router.location.hash) {
      setDisplayedSlides(null)
      if (find(nav, ['id', router.location.hash])) {
        setNav(
          nav.map((n) => {
            n.isActive = n.id === router.location.hash
            return n
          })
        )
      } else {
        setNav(
          nav.map((n) => {
            n.isActive = false
            return n
          })
        )
      }
    }
  }, [router.location.hash])

  useEffect(() => {
    if (slides) {
      let toDisplay = []

      const activeCat = find(nav, ['isActive', true])

      if (activeCat) {
        toDisplay = orderBy(
          slides.filter((s) => {
            if (activeCat.hasOwnProperty('author')) {
              return (
                s.authors.includes(activeCat.author) &&
                s.type.includes(activeCat.type)
              )
            } else {
              return s.type.includes(activeCat.type)
            }
          }),
          ['posFeatured'],
          ['asc']
        )
      } else {
        toDisplay = orderBy(
          slides.filter((s) => s.isFeatured && true),
          ['posFeatured'],
          ['asc']
        )
      }

      setDisplayedSlides(toDisplay)
    }
  }, [slides, nav])

  return (
    <section id="portfolio" className="Portfolio DefaultSection">
      {displayedSlides && nav && showPositionPopUp && (
        <PositionPopUp
          posProp={find(nav, ['isActive', true]) ? 'pos' : 'posFeatured'}
          items={displayedSlides}
          onClose={() => setShowPositionPopUp(false)}
        />
      )}
      <div className="container Protfolio-Container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Портфолио" />
            {user && (
              <div
                style={{
                  marginTop: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  title="Добавить проект"
                  theme="solid"
                  fill="black"
                  icon="plus"
                  iconPosition="right"
                  type="navlink"
                  path="/manage-project"
                  size={48}
                />
                <Button
                  theme="solid"
                  fill="black"
                  icon="sliders-v"
                  size={48}
                  className="SettingsButton"
                  onClick={() => setShowPositionPopUp(true)}
                />
              </div>
            )}
            <div className="Portfolio-NavContainer">
              <div className="Portfolio-Nav">
                {nav.map((n) => (
                  <PortfolioNavLink
                    title={n.title}
                    isActive={n.isActive}
                    link={`/${n.id}`}
                    scrollOffset={n.scrollOffset}
                    defaultLinkTo={`/#portfolio`}
                  />
                ))}
              </div>
            </div>
            {!isEmpty(displayedSlides) ? (
              <Slider
                slides={displayedSlides}
                elementKey={
                  find(nav, ['isActive', true])
                    ? find(nav, ['isActive', true]).id
                    : 'featured'
                }
              />
            ) : (
              <div className="Portfolio-Skeleton">
                <Media
                  query="(max-width: 576px)"
                  render={() => (
                    <>
                      <Skeleton height={390} />
                    </>
                  )}
                />
                {/* <Skeleton height={390} />
                <Skeleton height={390} />
                <Skeleton height={390} />
                <Skeleton height={390} /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export function PortfolioNavLink({
  title,
  link,
  defaultLinkTo,
  scrollOffset = -64,
  isActive,
}) {
  return (
    <HashLink
      to={isActive && defaultLinkTo ? defaultLinkTo : link}
      smooth
      scroll={(el) => scrollWithOffset(el, scrollOffset)}
      className={[
        'Portfolio-NavLink',
        ...(isActive ? ['Portfolio-NavLink_theme_active'] : []),
      ].join(' ')}
    >
      {title}
    </HashLink>
  )
}

export default withRouter(Portfolio)
