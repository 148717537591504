import React, { useEffect, createContext, useState } from 'react'
import { Route } from 'react-router-dom'
import Home from './pages/Home'
import SitePage from './pages/SitePage/SitePage'
import ProjectPage from './pages/ProjectPage/ProjectPage'
import Auth from './pages/Auth/Auth'
import { firebase } from './config/firebase'
import ManageProjectPage from './pages/ProjectPage/ManageProjectPage'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import Boutique from './pages/Boutique'
import CookieNotification from './components/CookieNotification/CookieNotification'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState(false)

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, showContactUs, setShowContactUs }}>
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Route path="/" exact component={Home} />
        <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} />
        <Route path="/boutique" exact component={Boutique} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
