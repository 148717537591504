import React, { useEffect, useState } from 'react'
import './ProductManager.sass'
import ReactPlayer from 'react-player'
import { isEmpty, omit } from 'lodash'
import PopUp from '../../../ui/PopUp/PopUp'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import { productCategories } from '../Boutique'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import Icon from '../../../ui/Icon/Icon'
import FieldRender from '../../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../../utils/files/fileToBase64'
import Button from '../../../ui/Button/Button'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import uploadVideos from '../../../pages/ProjectPage/functions/uploadVideos'
import uploadPhotos from '../../../pages/ProjectPage/functions/uploadPhotos'
import getDoc from '../../../utils/db/getDoc'
import Spinner from '../../../ui/Spinner/Spinner'

function ProductManager({ productId, onClose }) {
  const [form, setForm] = useState(
    !productId ? createForm({ formPattern: new ProductForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    if (productId) {
      getDoc({ path: 'products', docId: productId }).then((data) =>
        setForm(createForm({ formPattern: new ProductForm(), formData: data }))
      )
    }
  }, [])

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 5000,
      })
    }

    if (isEmpty(form.photos.values)) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавъте фото товара',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
      video: omit(data.video, [
        'file',
        'fileUrl',
        'imageUrl',
        'needUpload',
        'fieldId',
        'uploadProgress',
      ]),
    }

    const opRef = productId
      ? updateDoc({ path: 'products', docId: productId, data: values })
      : addDoc({ path: 'products', data: values })

    opRef.then((docId) =>
      Promise.all([
        uploadVideos({
          form,
          setForm,
          docId,
          docPath: 'products',
          fieldProps: ['video'],
          storagePath: 'products',
        }),
        uploadPhotos({
          photos: data.photos,
          docId,
          docPath: 'products',
          storagePath: 'products',
        }),
      ])
        .then(() => {
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <PopUp
      title={`${productId ? 'Редактировать' : 'Добавить новый'} товар`}
      show
      className="ProductManager"
      close={onClose}
    >
      {form ? (
        <>
          <div
            className={`Project-Status Project-Status_${
              form.isPublished.value ? 'published' : 'not_published'
            }`}
          >
            Этот проект {!form.isPublished.value ? 'не' : ''} будет опубликован
            после сохранения.{' '}
          </div>
          <div className="ProductManager-Container">
            <div className="ProductManager-Section ProductManager-ProductSection">
              <p className="Title">Товар</p>
              {statusMessage.show && (
                <StatusMessage
                  className="Site-StatusMessage"
                  type={statusMessage.type}
                  message={statusMessage.message}
                />
              )}
              <div className="ProductManager-Form">
                <FormRender
                  sections={[
                    {
                      fields: [
                        'isPublished',
                        'pos',
                        'category',
                        'title',
                        'price',
                        'colors',
                        'sizes',
                      ],
                    },
                  ]}
                  form={form}
                  setForm={setForm}
                  errors={showErrors}
                />
              </div>
              <div className="Buttons">
                <Button
                  title="Сохранить"
                  theme="solid"
                  fill="black"
                  icon="long-arrow-right"
                  iconPosition="right"
                  size={48}
                  isLoading={isLoading}
                  onClick={onSubmit}
                />
              </div>
            </div>
            <div className="ProductManager-Section ProductManager-MediaSection">
              <p className="Title">Фото</p>
              <PhotoManager
                projectId={productId}
                formData={form ? form.photos.values : null}
                syncState={(moduleState) =>
                  syncForm({
                    propIdentifier: 'photos',
                    form,
                    setForm,
                    newForm: moduleState,
                  })
                }
              />
              {form && (
                <>
                  <p className="Title">Видео</p>
                  <div className="EmptyField">
                    {form.video.value.publicUrl || form.video.value.fileUrl ? (
                      <ReactPlayer
                        url={
                          form.video.value.publicUrl || form.video.value.fileUrl
                        }
                        width="100%"
                        height="100%"
                        controls
                      />
                    ) : (
                      <>
                        <Icon name="film" />
                        <FieldRender
                          key="clip"
                          field={form.video}
                          form={form}
                          setForm={(newForm) => onFileAdded(newForm, 'video')}
                          showErrors={showErrors}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <Spinner type="module" />
      )}
    </PopUp>
  )
}

class ProductForm {
  constructor() {
    this.isPublished = {
      field: {
        fieldId: 'isPublished',
        fieldType: 'checkbox',
        label: 'Опубликовать',
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция',
      },
      render: getFieldRenderObject(),
    }
    this.category = {
      field: {
        fieldId: 'category',
        fieldType: 'select',
        label: 'Категория',
        getOptions: productCategories.map((c) => ({
          label: c.title,
          value: c.id,
        })),
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название товара',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'input',
        inputType: 'number',
        label: 'Стоимость',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.colors = {
      field: {
        fieldId: 'colors',
        fieldType: 'select',
        label: 'Цвета',
        getOptions: productColors,
        required: true,
        multiOptions: true,
      },
      render: getFieldRenderObject(),
    }
    this.sizes = {
      field: {
        fieldId: 'sizes',
        fieldType: 'select',
        label: 'Размеры',
        getOptions: productSizes,
        required: true,
        multiOptions: true,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.video = {
      field: {
        fieldId: 'video',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
  }
}

export const productColors = [
  { label: 'Серый', value: 'gray' },
  { label: 'Пурпурный', value: 'lightPink' },
  { label: 'Бежевый', value: 'milk' },
  { label: 'Белый', value: 'white' },
  { label: 'Розовый', value: 'pink' },
  { label: 'Зеленый', value: 'green' },
]

const productSizes = [
  { label: 'XS', value: 'xs' },
  { label: 'S', value: 's' },
  { label: 'M', value: 'm' },
  { label: 'L', value: 'l' },
]

export default ProductManager
