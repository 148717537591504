import React, { useEffect, useState } from 'react'
import './ProjectPage.sass'
import ReactPlayer from 'react-player'
import { find, isEmpty, omit, toNumber } from 'lodash'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import { createForm } from '../../utils/newforms/createForm'
import FormRender from '../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import addDoc from '../../utils/db/addDoc'
import Icon from '../../ui/Icon/Icon'
import FieldRender from '../../utils/newforms/render/FieldRender'
import syncForm from '../../utils/newforms/changed/syncForm'
import fileToBas64 from '../../utils/files/fileToBase64'
import { updateDoc } from '../../utils/db/updateDoc'
import uploadVideos from './functions/uploadVideos'
import PhotoManager from '../../components/PhotoManager/PhotoManager'
import uploadPhotos from './functions/uploadPhotos'
import getDoc from '../../utils/db/getDoc'
import Spinner from '../../ui/Spinner/Spinner'

function NewProjectPage({ ...router }) {
  const projectId = router.match.params.id
  const [form, setForm] = useState(
    !projectId ? createForm({ formPattern: new ProjectForm() }) : null
  )

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (projectId) {
      getDoc({ path: 'projects', docId: projectId }).then((data) =>
        setForm(createForm({ formPattern: new ProjectForm(), formData: data }))
      )
    }
  }, [projectId])

  const onFileAdded = (newForm, fieldId) => {
    fileToBas64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
    }

    if (isEmpty(form.photos.values)) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавъте аватар к проекту',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      pos: toNumber(data.pos),
      posFeatured: toNumber(data.posFeatured),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
      videoTeaser: omit(data.videoTeaser, [
        'file',
        'fileUrl',
        'imageUrl',
        'needUpload',
        'fieldId',
        'uploadProgress',
      ]),
      videoClip: omit(data.videoClip, [
        'file',
        'fileUrl',
        'imageUrl',
        'needUpload',
        'fieldId',
        'uploadProgress',
      ]),
    }

    const opRef = projectId
      ? updateDoc({ path: 'projects', docId: projectId, data: values })
      : addDoc({ path: 'projects', data: values })

    opRef.then((docId) =>
      Promise.all([
        uploadVideos({ form, setForm, docId }),
        uploadPhotos({ photos: data.photos, docId }),
      ])
        .then(() => {
          router.history.push(`/manage-project/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  const getPoster = ({ prop, photos }) => {
    const photo = find(photos, [prop, true])
    console.log(
      '🚀 ~ file: ManageProjectPage.jsx ~ line 132 ~ getPoster ~ photo',
      photo
    )
    if (photo) {
      return photo.imageUrl || photo.publicUrl
    } else return false
  }

  return (
    <section className="ProjectPage">
      <div className="ProjectPage-Container">
        <div className="Project-Details" style={{ gridGap: '36px' }}>
          <h3>{projectId ? 'Редактировать' : 'Новый'} проект</h3>
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FormRender
            sections={[
              {
                fields: [
                  'isPublished',
                  'isFeatured',
                  'title',
                  'date',
                  'type',
                  'category',
                  'authors',
                ],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="gray"
              icon="long-arrow-right"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
        <div className="Project-Content">
          {form && projectId && (
            <div
              className={`Project-Status Project-Status_${
                form.isPublished.value ? 'published' : 'not_published'
              }`}
            >
              Этот проект {!form.isPublished.value ? 'не' : ''} будет
              опубликован после сохранения.{' '}
              <NavLink to={`/project/${projectId}`}>Просмотреть проект</NavLink>
            </div>
          )}
          {form ? (
            <>
              <div className="Content-Title">Видео</div>
              <div className="Content">
                <div className="Content-Block">
                  <div className="EmptyField">
                    {form.videoTeaser.value.publicUrl ||
                    form.videoTeaser.value.fileUrl ? (
                      <ReactPlayer
                        url={
                          form.videoTeaser.value.publicUrl ||
                          form.videoTeaser.value.fileUrl
                        }
                        width="100%"
                        height="100%"
                        controls
                        light={getPoster({
                          prop: 'isTeaser',
                          photos: form.photos.values,
                        })}
                      />
                    ) : (
                      <>
                        <Icon name="film" />
                        <FieldRender
                          key="teaser"
                          field={form.videoTeaser}
                          form={form}
                          setForm={(newForm) =>
                            onFileAdded(newForm, 'videoTeaser')
                          }
                          showErrors={showErrors}
                        />
                      </>
                    )}
                  </div>
                  <p>Тизер</p>
                </div>
                <div className="Content-Block">
                  <div className="EmptyField">
                    {form.videoClip.value.publicUrl ||
                    form.videoClip.value.fileUrl ? (
                      <ReactPlayer
                        url={
                          form.videoClip.value.publicUrl ||
                          form.videoClip.value.fileUrl
                        }
                        width="100%"
                        height="100%"
                        controls
                        light={getPoster({
                          prop: 'isClip',
                          photos: form.photos.values,
                        })}
                      />
                    ) : (
                      <>
                        <Icon name="film" />
                        <FieldRender
                          key="clip"
                          field={form.videoClip}
                          form={form}
                          setForm={(newForm) =>
                            onFileAdded(newForm, 'videoClip')
                          }
                          showErrors={showErrors}
                        />
                      </>
                    )}
                  </div>
                  <p>Клип</p>
                </div>
              </div>
              <div className="Content-Title">Фото</div>
              <PhotoManager
                projectId={projectId}
                formData={form ? form.photos.values : null}
                syncState={(moduleState) =>
                  syncForm({
                    propIdentifier: 'photos',
                    form,
                    setForm,
                    newForm: moduleState,
                  })
                }
              />
            </>
          ) : (
            <Spinner type="module" />
          )}
        </div>
      </div>
    </section>
  )
}

class ProjectForm {
  constructor() {
    this.isPublished = {
      field: {
        fieldId: 'isPublished',
        fieldType: 'checkbox',
        label: 'Опубликовать',
      },
      render: getFieldRenderObject(),
    }
    this.isFeatured = {
      field: {
        fieldId: 'isFeatured',
        fieldType: 'checkbox',
        label: 'Избранный проект',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название проекта',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.posFeatured = {
      field: {
        fieldId: 'posFeatured',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция в избранном',
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция в своей категории',
      },
      render: getFieldRenderObject(),
    }
    this.date = {
      field: {
        fieldId: 'date',
        fieldType: 'input',
        inputType: 'number',
        label: 'Дата события',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.type = {
      field: {
        fieldId: 'type',
        fieldType: 'select',
        label: 'Тип проекта',
        multiOptions: true,
        getOptions: [
          { label: 'Видео', value: 'video' },
          { label: 'Фото', value: 'photo' },
          { label: 'Флористика', value: 'floristics' },
        ],
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.category = {
      field: {
        fieldId: 'category',
        fieldType: 'select',
        label: 'Категория',
        getOptions: categories,
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.authors = {
      field: {
        fieldId: 'authors',
        fieldType: 'select',
        label: 'Авторы',
        multiOptions: true,
        getOptions: [
          { label: 'Вера', value: '6SAepbr8N0ZoNeNUKceMZaNGvlt1' },
          { label: 'Алёна', value: 'Y00cIUT5l2hf8XvOREqW5jhZNoW2' },
          { label: 'Сима', value: 'HeEoslIiF4fdwoLCw1cpjZXPmAc2' },
          { label: 'Маша', value: 'yvP2ZnsvEQhoy52vfCiLYDbGbXA2' },
        ],
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.videoClip = {
      field: {
        fieldId: 'videoClip',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.videoTeaser = {
      field: {
        fieldId: 'videoTeaser',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export const categories = [
  { label: 'Свадебный день', value: 'wedding' },
  { label: 'Семейная съемка', value: 'family' },
  { label: 'LoveStory', value: 'lovestory' },
  { label: 'Свадебная флористика', value: 'weddingFlor' },
  { label: 'Декор в стиле элегантного минимализма', value: 'decoMinimal' },
  { label: 'Оформление камерной свадьбы', value: 'decoCamer' },
]

export default withRouter(NewProjectPage)
