import React from 'react'
import './Logo.sass'
import { NavLink } from 'react-router-dom'
import logoWhite from './assets/odlo.svg'
import logoBlack from './assets/logoBlack.svg'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'

function Logo({ theme, navLink = false, path }) {
  let src = ''
  switch (theme) {
    case 'white':
      src = logoWhite
      break
    default:
      src = logoBlack
      break
  }

  return navLink ? (
    <NavHashLink
      to={path}
      className="Logo"
      smooth
      scroll={(el) => scrollWithOffset(el, -64)}
    >
      <img src={src} alt="OneDay" />
    </NavHashLink>
  ) : (
    <a href={path} className="Logo">
      <img src={src} alt="OneDay" />
    </a>
  )
}

export default Logo
