import { cloneDeep, isEmpty } from 'lodash'
import { storageRef } from '../../../config/firebase'
import { updateDoc } from '../../../utils/db/updateDoc'

export const deletePhoto = ({
  photoIndex,
  photos,
  setPhotos,
  projectId,
  docPath = 'projects',
}) => {
  const photosClone = cloneDeep(photos)
  const photo = photosClone[photoIndex]

  photosClone.splice(photoIndex, 1)
  if (photo.isAvatar && !isEmpty(photosClone)) {
    photosClone[0].isAvatar = true
  }

  if (photo.storagePath) {
    storageRef
      .child(
        `${photo.storagePath}/${docPath === 'products' ? `${projectId}/` : ''}${
          photo.fileName
        }.${photo.fileExt}`
      )
      .delete()
      .catch((e) =>
        console.log('🚀 ~ file: deletePhoto.js ~ line 29 ~ deletePhoto ~ e', e)
      )
      .finally(() =>
        updateDoc({
          path: docPath,
          docId: projectId,
          data: { photos: photosClone.filter((p) => !p.needUpload) },
        }).then(() => setPhotos(photosClone))
      )
  } else {
    setPhotos(photosClone)
  }
}
