import React from 'react'
import Hero from '../components/Hero/Hero'
import ContactUs from '../components/ContactUs/ContactUs'
import Catalog from '../components/Boutique/Boutique'
// import ConversionBlock from '../../components/ConversionBlock/ConversionBlock'
// import AboutEstate from '../../components/AboutEstate/AboutEstate'
// import CrmRender from '../../components/CrmRender/CrmRender'

function Boutique() {
  return (
    <div className="Boutique">
      <Hero
        title={
          <>
            Начни этот день <span>красиво</span>
          </>
        }
        description="Прокат пеньюаров и будуарных платьев для невест, халатиков для подружек — с любовью."
        boutique
      />
      <Catalog />
      <ContactUs />
    </div>
  )
}

export default Boutique
