import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDtOd4Ubf9b7TXNBgD6e0mGRcz1a2pQCV4',
  authDomain: 'oneday-tv.firebaseapp.com',
  projectId: 'oneday-tv',
  storageBucket: 'oneday-tv.appspot.com',
  messagingSenderId: '373684814757',
  appId: '1:373684814757:web:01512bf55653e5b1079e4c',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
