import React, { useContext } from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Button from '../../ui/Button/Button'
import weddingVideo from './newv.webm'
import boutiqueVideo from './bvideo.webm'
import Icon from '../../ui/Icon/Icon'
import { AuthContext } from '../../App'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import boutiquePoster from './boutiquePoster.jpg'
import mainPoster from './mainPoster.jpg'

function Hero({
  user,
  title = (
    <>
      <span>Любовь</span> в каждом кадре
    </>
  ),
  description = 'Свадебная видео и фотосъемка, флористика и декор',
  boutique,
  ...router
}) {
  const { setShowContactUs } = useContext(AuthContext)
  const scrollOffset = -64

  return (
    <div id="home" className="Site-Hero">
      <video
        autoPlay
        loop
        muted
        preload="metadata"
        poster={boutique ? boutiquePoster : mainPoster}
      >
        <source
          src={boutique ? boutiqueVideo : weddingVideo}
          type="video/mp4"
        />
      </video>
      <div className="container Site-HeroContent">
        <div className="row">
          <div className="col-12">
            <div className="Hero-Offer-Container">
              <div className="Hero-Offer">
                <h1 className="Offer-Title">{title}</h1>
                <p className="Offer-Description">{description}</p>
                <div className="Offer-Buttons">
                  {boutique ? (
                    <HashLink
                      to="/boutique#boudoir-dresses"
                      smooth
                      scroll={(el) => scrollWithOffset(el, scrollOffset)}
                    >
                      <Button
                        title="Каталог товаров"
                        theme="solid"
                        fill="white"
                        icon="long-arrow-right"
                        iconPosition="right"
                        size={48}
                      />
                    </HashLink>
                  ) : (
                    <>
                      <Button
                        title="Узнать подробнее"
                        theme="solid"
                        fill="white"
                        icon="long-arrow-right"
                        iconPosition="right"
                        size={48}
                        onClick={() =>
                          setShowContactUs({
                            show: true,
                            title: 'Узнать подробнее',
                            emailSubject: 'Обратная связь с сайта',
                          })
                        }
                      />
                      <HashLink
                        to="/#portfolio"
                        smooth
                        scroll={(el) => scrollWithOffset(el, scrollOffset)}
                      >
                        <Button
                          title="Смотреть портфолио"
                          theme="bounded"
                          color="white"
                          border="white"
                          size={48}
                        />
                      </HashLink>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Hero-SocialBlock">
        <a href="https://www.instagram.com/oneday__tv/" target="blank">
          <Icon name="instagram" weight="brand" />
        </a>
        <a href="https://vk.com/tvoneday" target="blank">
          <Icon name="vk" weight="brand" />
        </a>
        <Icon
          name="envelope"
          onClick={() =>
            setShowContactUs({
              show: true,
              title: 'Свяжитесь с нами',
              emailSubject: 'Обратная связь с сайта',
            })
          }
          weight="solid"
        />
      </div>
    </div>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Hero)
