import React, { useEffect, useState, useRef } from 'react'
import './Slider.sass'
import { isEmpty } from 'lodash'
import Carousel from '../Carousel/Carousel'
import ProjectTile from '../../components/Portfolio/ProjectTile/ProjectTile'

function Slider({ elementKey, slides = [] }) {
  const slideRef = useRef()

  const [containerWidth, setContainerWidth] = useState(null)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  const updateWindowDimensions = () =>
    setContainerWidth(slideRef.current.clientWidth)

  return (
    <div ref={slideRef} className="Slider">
      <Carousel
        key={elementKey}
        element="PortfolioCarousel"
        options={{
          type: 'carousel',
          gap: 24,
          perView: 5,
          ...(!isEmpty(slides) ? { autoplay: 5000 } : {}),
          useDots: false,
          useArrows: true,
          breakpoints: {
            1400: { perView: 4 },
            1024: { perView: 2 },
            576: { perView: 1 },
          },
        }}
      >
        {slides
          ? slides.map((s) => (
              <ProjectTile
                key={`${elementKey}-${s.id}`}
                elementKey={`${elementKey}-${s.id}`}
                {...s}
                className={s.id}
              />
            ))
          : null}
      </Carousel>
    </div>
  )
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
// })

export default Slider
