import React from 'react'
import './Skeleton.sass'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const ThemedSkeleton = (props) =>
  props.useTheme ? (
    <SkeletonTheme color={props.color} highlightColor={props.highlightColor}>
      <Skeleton {...props} />
    </SkeletonTheme>
  ) : (
    <Skeleton {...props} />
  )
export default ThemedSkeleton
