import React from 'react'
import Hero from '../components/Hero/Hero'
import Portfolio from '../components/Portfolio/Portfolio'
import Services from '../components/Services/Services'
import Team from '../components/Team/Team'
import ContactUs from '../components/ContactUs/ContactUs'
import { Helmet } from 'react-helmet'
import Feeds from '../components/Feeds/Feeds'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Portfolio />
      <Services />
      <Team />
      {/* <Feeds /> */}
      <ContactUs />
    </div>
  )
}

export default Home
