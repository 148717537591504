import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './LangPicker.sass'

function LangPicker() {
  return (
    <p className="LangPicker">
      <Icon name="globe-americas" weight="solid" />
      <span>RU</span>
      {/* <Icon name="angle-down" weight="solid" /> */}
    </p>
  )
}

export default LangPicker
