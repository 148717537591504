import React, { useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import Logo from '../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import LangPicker from './LangPicker/LangPicker'
import LocationPicker from './LocationPicker/LocationPicker'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  const isTransparent = !(
    pos > 0 ||
    (router.location.pathname !== '/' &&
      router.location.pathname !== '/boutique')
  )

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(isTransparent && !menuIsOpen ? ['Site-Nav_theme_transparent'] : []),
      ].join(' ')}
    >
      <Media
        query="(max-width: 1024px)"
        render={() => (
          <span ref={hamRef}>
            <Hamburger
              isOpen={menuIsOpen}
              width={24}
              height={16}
              strokeWidth={2}
              className="Site-Hamburger-Container"
              color={'#fff'}
              borderRadius={2}
              animationDuration={0.2}
            />
          </span>
        )}
      />
      <Logo theme="white" navLink path="/#home" />
      <div
        ref={navRef}
        className="Site-Nav-Menu"
        style={menuIsOpen ? { display: 'grid' } : {}}
      >
        <SiteNavLink title="Главная" path="/#home" />
        <SiteNavLink title="Портфолио" path="/#portfolio" />
        <SiteNavLink title="Услуги" path="/#services" />
        <SiteNavLink title="Команда" path="/#team" />
        <SiteNavLink title="Контакты" path="/#contacts" />
      </div>
      <LangPicker />
    </nav>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
